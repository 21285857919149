import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {ConnectFourPage} from "./connect-four-page";
import {unregister} from "./registerServiceWorker";

const rootElement = document.getElementById('root');
ReactDOM.render(<ConnectFourPage/>, rootElement);
unregister();


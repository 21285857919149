﻿// inspiration from:
// https://pimpmytype.com/review-tech-blog/﻿

export const Theme = {
    subtitleColor: '#5d6266',
    titleFontFamily: 'basic-sans,Helvetica,Arial,sans-serif',
    storyFontFamily: 'bressay,Georgia,serif',
}

export function px(size: number): string {
    return `${size}px`;
}

// device constants
export const MIN_PAGE_WIDTH = '320px';
export const MIN_DESKTOP_PAGE_WIDTH = '600px';
export const MOBILE = `@media all and (max-width: ${MIN_DESKTOP_PAGE_WIDTH})`;
export const DESKTOP = `@media all and (min-width: ${MIN_DESKTOP_PAGE_WIDTH})`;
export const HOVERABLE = `@media (hover: hover)`;
export const DEFAULT = "@media all";
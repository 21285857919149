import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import {GameState} from "./models/game-state";

const tokenWidth = (100 * 0.50) / 7;
const BoardContainer = styled.div({
    position: 'absolute',
    transform: 'translate(-50%, calc(-50%))',
    top: '50%',
    left: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
});

const BoardGrid = styled.div({
    display: 'grid',
    gridTemplateRows: `min(${0.4 * tokenWidth}vw, 40px) repeat(6, 1fr)`,
    gridTemplateColumns: 'repeat(7, 1fr)',
});
type GridCellProps = {
    row: number,
    column: number,
}

const GridCell = styled.div((props:GridCellProps) => ({
    gridRow: `${props.row + 1}`,
    gridColumn: `${props.column + 1}`,
    padding: '0.5vw 1vw',
}));

type BoardPieceProps = {
    pieceState: string,
};

// @ts-ignore
const BoardPiece = styled.div(({pieceState}:BoardPieceProps) => ({
    backgroundColor: pieceState === 'r' ? 'maroon' : pieceState === 'b' ? 'black' : 'white',
    borderStyle: pieceState === '' ? 'solid' : 'solid',
    borderWidth: '1px',
    borderColor: pieceState === 'r' ? 'maroon' : pieceState === 'b' ? 'black' : 'white',
    borderRadius: '50%',
    position: 'relative',
    width: `${tokenWidth}vw`,
    height: `${tokenWidth}vw`,
    maxWidth: '75px',
    maxHeight: '75px',
}));

const DownArrow = styled.div({
    width: 0,
    height: 0,
    borderLeft: `min(${0.3 * tokenWidth}vw, 20px) solid transparent`,
    borderRight: `min(${0.3 * tokenWidth}vw, 20px) solid transparent`,
    borderTop: `min(${0.2 * tokenWidth}vw, 15px) solid gold`,
    margin: 'auto auto'
})

const Exclamation = styled(DownArrow)({
    borderTop: `min(${0.2 * tokenWidth}vw, 15px) solid red`,
})

type Props = {
    gameState: GameState;
    onHover: (e: MouseEvent<HTMLDivElement>) => void,
    onClick: (e: MouseEvent<HTMLDivElement>) => void,
}

export function ConnectFourGame({gameState, onHover, onClick}: Props) {
    let gridCells = [];
    
    gameState.forcedColumns.forEach(forcedColumn => {
        if (forcedColumn === gameState.columnHover) return;
        const gridCell = (
            <GridCell key={`check-${forcedColumn}`} row={0} column={forcedColumn}>
                <Exclamation/>
            </GridCell>
        );
        gridCells.push(gridCell);
    });
    
    if (gameState.columnHover !== undefined) {
        const gridCell = (
            <GridCell key={'hover'} row={0} column={gameState.columnHover}>
                <DownArrow/>
            </GridCell>
        );
        gridCells.push(gridCell);
    }
    
    for(let r = 0; r < gameState.board.length; r++) {
        for(let c = 0; c < gameState.board[r].length; c++) {
            const key = r * 13 + c;
            const pieceState = gameState.board[r][c];
            const gridCell = (
                <GridCell key={key} row={r + 1} column={c} onMouseOver={onHover} onClick={onClick} data-column={c}>
                    {/*@ts-ignore*/}
                    <BoardPiece pieceState={pieceState}>
                    </BoardPiece>
                </GridCell>
            );
            gridCells.push(gridCell);
        }
    }
    return (
        <BoardContainer>
            <BoardGrid onMouseLeave={onHover}>
                {gridCells}
            </BoardGrid>
        </BoardContainer>
    );
}
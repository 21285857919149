import React from 'react';
import {ConnectFourGameController} from "./connect-four-game-controller";
import styled from "styled-components";
import {DESKTOP, MOBILE, Theme} from "./theme";

const Container = styled.div({
    width: '100%',
    minHeight: '100%',
});

const Spacing = styled.div({
    height: '45px',
});

const GameContainer = styled.div({
    width: '100%',
    height: '60vw',
    maxHeight: '80vh',
    position: 'relative',
});

const Title = styled.h1({
    fontFamily: Theme.titleFontFamily,
    textAlign: 'center',
    fontSize: '2.6em',
    fontWeight: 'bold',
    lineHeight: '3rem',
    marginBottom: '3rem',
});

const Subtitle = styled.div({
    color: Theme.subtitleColor,
    fontFamily: Theme.titleFontFamily,
    textAlign: 'center',
    fontSize: '1em',
    lineHeight: '2rem',
});

const PageBody = styled.div({
    color: 'black',
    fontFamily: 'bressay,Georgia,serif',
    fontSize: '1.2em',
    lineHeight: '2rem',
    whiteSpace: 'pre-wrap',
    paddingBottom: '3rem',

    [DESKTOP]: {
        marginTop: '4rem',
        width: '35%',
        minWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    [MOBILE]: {
        marginLeft: '30px',
        marginRight: '30px',
        marginTop: '2rem',
    },
})

export function ConnectFourPage() {
    return (
        <Container>
            <Spacing/>
            <GameContainer>
                <ConnectFourGameController/>
            </GameContainer>
            <PageBody>
                <Title>Connect Four</Title>
                <p>
                    I'm pretty good at connect four. It's not difficult to play a winning strategy. 
                    This is in fact a "solved" game. In perfect play, the first player will always win. 
                    However, I've come across embarassing situations where I've lost a match,
                    even after making such hubristic claims! 
                </p>
                <p>
                    Most embarassingly, I've lost on silly mistakes where I simply didn't realize a threat.
                    I wasn't aware that my opponent had lined up three tokens and would win on the next move 
                    unless I blocked. So rather than improve my concentration in the game, I decided to build 
                    this version of Connect-Four, where the rules are modified to my favor 🙂
                </p>
                <p>
                    I borrowed some ideas from Chess:
                </p>
                <ul>
                    <li>This version of Connect-Four has "forced" moves</li>
                    <li>If you are about to lose unless you block you opponent, you are in "Check"</li>
                    <li>If you are in "Check", you must block your opponent</li>
                    <li>If you have a winning move, you must make the winning move</li>
                </ul>
                <p>Enjoy!</p>
            </PageBody>
        </Container>
    );
}
import React from 'react';
import {Button, Modal} from "react-bootstrap";

type Props = {
    isOpen: boolean,
    winner: string,
    onNewGame: () => void,
}

export function GameOverModal({isOpen, winner, onNewGame}: Props) {
    return (
        <Modal show={isOpen} onHide={onNewGame}>
            <Modal.Header closeButton>
                <Modal.Title>Game Over</Modal.Title>
            </Modal.Header>
            <Modal.Body>{winner} wins!</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onNewGame}>
                    New Game
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export type Board = string[][];
export type GameState = {
    turn: string,
    board: Board,
    columnHover?: number,
    forcedColumns: number[],
}

export function NewBoard(): Board {
    return [
        ['', '', '', '', '', '', ''],
        ['', '', '', '', '', '', ''],
        ['', '', '', '', '', '', ''],
        ['', '', '', '', '', '', ''],
        ['', '', '', '', '', '', ''],
        ['', '', '', '', '', '', ''],
    ];
}
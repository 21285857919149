import React, {MouseEvent, useState} from 'react';
import {Board, GameState, NewBoard} from "./models/game-state";
import {ConnectFourGame} from "./connect-four-game";
import {cloneDeep} from 'lodash'
import {GameOverModal} from "./game-over-modal";


const initialGameState: GameState = Object.freeze({
    board: NewBoard(),
    turn: "r",
    forcedColumns: [],
});

export function ConnectFourGameController() {
    const [gameState, setGameState] = useState(initialGameState);
    const [isGameOverModalOpen, setIsGameOverModalOpen] = useState(false);
    const winner = GetWinner(gameState.board);
    
    if (winner && !isGameOverModalOpen) {
        setIsGameOverModalOpen(true);
    }

    function onNewGame() {
        setGameState(initialGameState);
        setIsGameOverModalOpen(false);
    }
    
    function onHover(e: MouseEvent<HTMLDivElement>) {
        const column = e.currentTarget.dataset['column'] !== undefined
            ? parseInt(e.currentTarget.dataset['column'])
            : undefined;
        
        const newGameState = cloneDeep(gameState);
        newGameState.columnHover = isLegalMove(gameState, column) ? column : undefined;
        setGameState(newGameState);
    }
    
    function onClick(e: MouseEvent<HTMLDivElement>) {
        if (e.currentTarget.dataset['column'] === undefined) return;
        const column = parseInt(e.currentTarget.dataset['column']);
        makeMove(column);
    }
    
    function isLegalMove(gameState: GameState, column?: number) {
        if (column === undefined) return false;
        if (!isValidMove(gameState.board, column)) return false;
        return gameState.forcedColumns.length === 0 || gameState.forcedColumns.includes(column);
    }
    
    function isValidMove(board: Board, column?: number) {
        if (column === undefined) return false;
        return board[0][column] === '';
    }
    
    function makeMove(column: number) {
        if (!isLegalMove(gameState, column)) return;
        const newGameState = cloneDeep(gameState);
        newGameState.board = addPieceToBoard(newGameState.board, column, newGameState.turn);
        newGameState.turn = newGameState.turn === 'r' ? 'b' : 'r';
        newGameState.forcedColumns = getForcedColumns(newGameState.board, newGameState.turn);
        newGameState.columnHover = isLegalMove(newGameState, newGameState.columnHover) ? newGameState.columnHover : undefined;
        setGameState(newGameState);
    }
    
    function addPieceToBoard(board: Board, column: number, turn: string): Board {
        if (!isValidMove(board, column)) return board;
        const row = [0, 1, 2, 3, 4, 5]
            .map(r => board[r][column])
            .lastIndexOf('');
        
        const newBoard = cloneDeep(board);
        newBoard[row][column] = turn;
        return newBoard;
    }
    
    function getForcedColumns(board: Board, turn: string): number[] {
        if (GetWinner(board)) return [];
        let forcedColumns = [];
        let opponentTurn = turn === 'r' ? 'b' : 'r';
        
        // If you can win, you must win!
        for(let c = 0; c < board[0].length; c++) {
            const nextBoardState = addPieceToBoard(board, c, turn);
            const winner = GetWinner(nextBoardState);
            if (winner === turn) {
                forcedColumns.push(c);
            }
        }
        
        // If you can block, you must block!
        if (forcedColumns.length === 0) {
            for(let c = 0; c < board[0].length; c++) {
                const nextBoardState = addPieceToBoard(board, c, opponentTurn);
                const winner = GetWinner(nextBoardState);
                if (winner === opponentTurn) {
                    forcedColumns.push(c);
                }
            }
        }
        
        return forcedColumns;
    }
    
    function GetWinner(board: Board): string | undefined {
        let isDraw = true;
        for(let r = 0; r < board.length; r++) {
            for(let c = 0; c < board[r].length; c++) {
                // check for draw
                if (board[r][c] === '') {
                    isDraw = false;
                }
                
                // check down
                if (r + 3 < board.length) {
                    if (board[r][c] !== '' &&
                        board[r][c] === board[r+1][c] &&
                        board[r][c] === board[r+2][c] &&
                        board[r][c] === board[r+3][c]) {
                        return board[r][c];
                    }
                }
                
                // check right
                if (c + 3 < board[r].length) {
                    if (board[r][c] !== '' && 
                        board[r][c] === board[r][c+1] &&
                        board[r][c] === board[r][c+2] &&
                        board[r][c] === board[r][c+3]) {
                        return board[r][c];
                    }
                }
                
                // check down-right diagonal
                if (r + 3 < board.length && c + 3 < board[r].length) {
                    if (board[r][c] !== '' &&
                        board[r][c] === board[r+1][c+1] &&
                        board[r][c] === board[r+2][c+2] &&
                        board[r][c] === board[r+3][c+3]) {
                        return board[r][c];
                    }
                }
                
                // check up-right diagonal
                if (r - 3 >= 0 && c + 3 < board[r].length) {
                    if (board[r][c] !== '' &&
                        board[r][c] === board[r-1][c+1] &&
                        board[r][c] === board[r-2][c+2] &&
                        board[r][c] === board[r-3][c+3]) {
                        return board[r][c];
                    }
                }
            }
        }
        
        return isDraw ? 'draw' : undefined;
    }
    
    return (
        <>
            <ConnectFourGame 
                gameState={gameState}
                onHover={onHover}
                onClick={onClick}
            />
            <GameOverModal 
                isOpen={isGameOverModalOpen} 
                winner={winner === 'r' ? "Red" : winner === 'b' ? "Black" : "Nobody"} 
                onNewGame={onNewGame}
            />
        </>
    );
}